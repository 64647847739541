import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  Redirect,
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FrameContainer from './FrameContainer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

}));


export default function OuterContainer() {
  const classes = useStyles();
  const [entity, setEntity] = useState('')
  const [type, setType] = useState('chamber')
  const [submitted, setSubmitted] = useState(false)

  const handleInput = (e) => {
    setEntity(e.target.value)
  }

  const resetInput = (e) => {
    setSubmitted(false)
    setEntity('')
  }

  const handleTypeChange = (e) => {
    setSubmitted(false)
    setEntity('')
    setType(e.target.value)
  }

  const resolveLink = () => {
    if (type==='execs') {
      return `/execs/${entity}`
    }
    return `/${entity}`
  }


  const keyPress = (e) => {
    if(e.keyCode === 13){
      setSubmitted(true)
      setTimeout(() => {
        document.activeElement.blur();
    }, 0);
    }
  }


  return (
    <div className={classes.root}>
      <Router>
        
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Box display="flex" p={2} justifyContent="center">
              <TextField
                id="outlined-basic"
                label={type==='chamber'? "Enter Chamber ID Here" : "Enter 2-Letter State ID Here"}
                variant="standard"
                onChange={handleInput}
                onFocus={resetInput}
                value={entity}
                onKeyDown={keyPress}
               />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <RadioGroup row aria-label="type" name="type" value={type} onChange={handleTypeChange}>
              <FormControlLabel value="chamber" control={<Radio />} label="Chamber" />
              <FormControlLabel value="execs" control={<Radio />} label="Execs by State" />
            </RadioGroup>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" p={2} justifyContent="center">
              <Link to={resolveLink()}>
                <Button 
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}>
                  Load
                </Button>
              </Link>
              <Link to=''>
                <Button 
                variant="contained" 
                color="secondary"
                startIcon={<ClearIcon />}
                >
                  Clear
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid> 
        { submitted &&
          
          <Redirect  to={resolveLink()}  />
        
        }
        <Switch>
          <Route path="/execs/:execs_state_abbr" children={<FrameContainer />} />
          <Route path="/:router_id" children={<FrameContainer />} />
        </Switch>
    </Router>
    </div>

  );

}