import React from 'react';
import OuterContainer from './components/OuterContainer';

function App() {
  return (
	<OuterContainer/>
  );
}

export default App;
