import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as csvtojson from "csvtojson";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import TitleIcon from "@material-ui/icons/Title";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ClearIcon from "@material-ui/icons/Clear";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import MapIcon from "@material-ui/icons/Map";
import Badge from "@material-ui/core/Badge";
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ListIcon from "@material-ui/icons/List";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const SERVER_ROOT = process.env.REACT_APP_CICERO_SERVER || "https://staging.cicero.azavea.com";

const REACT_APP_LOCAL_FLASK_PROXY = process.env.REACT_APP_LOCAL_FLASK_PROXY;

const socialTypes = ["FACEBOOK","FACEBOOK-OFFICIAL","FACEBOOK-CAMPAIGN","TWITTER","LINKEDIN","INSTAGRAM","INSTAGRAM-CAMPAIGN"]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  socialPaper: {
    padding: theme.spacing(0.5),
    "padding-bottom": theme.spacing(1.2),
    textAlign: "left",
    //color: theme.palette.text.secondary,
    background: "#ddd",
  },
  bannerButton: {
    borderRadius: "0%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor:"rgba(0,0,0,0.12)",
    backgroundColor: "#ddd",
    padding: "11px",
    verticalAlign: "top",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "yellow",
    },
    background: "#ddd",
  },
  tableCell: {
    "$selected &": {
      color: "yellow",
    },
  },
  link: {
    margin: theme.spacing(1),
  },
  selected: {},
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  smallIcon: {
    padding: 6,
  },
}));

const OfficialSort = {
  alpha: "alphabetical",
  district: "district",
  role: "role",
};

function isNumeric(obj) {
  const pobj = parseInt(obj, 10);
  return pobj !== undefined && typeof pobj === "number" && !isNaN(pobj);
}

export default function CenteredGrid() {
  const classes = useStyles();
  let { router_id, execs_state_abbr } = useParams();
  const [count, setCount] = useState(1);
  const [chamber, setChamber] = useState(router_id);
  const [execsState, setExecsState] = useState(execs_state_abbr);
  // The data from the API (unsorted)
  const [officialsData, setOfficialsData] = useState([]);
  const [officialSortOrder, setOfficialSortOrder] = useState(OfficialSort.alpha);
  const [socialSearchUrl, setSocialSearchUrl] = useState("https://www.google.com");
  const [socialCheckUrl, setSocialCheckUrl] = useState("");
  const [socialTableToggle, setSocialTableToggle] = useState(false);
  const [partyToggle, setPartyToggle] = useState(false);
  const [showMainSummary, setShowMainSummary] = useState(true);
  const [expandedColumn, setExpandedColumn] = useState("");
  const [openUrlDialog, setOpenUrlDialog] = useState(false);
  const [urlAppendString, setUrlAppendString] = useState("");

  const [leftPane, setLeftPane] = useState("chamber");
  const [rightPane, setRightPane] = useState("table");

  // The data used to power the app (may be sorted)
  const officialsState = useMemo(() => {
    // The sorting requirements here are multi-tiered. Here's how it works:
    // Officials with districts that have numeric IDs should always be sorted first over those with
    // text IDs.
    // Within the numeric ID group, officials should be sorted in numeric order.
    // Within the text group, officials should be sorted in alphabetical order by district ID.
    const sortByDistrict = (o1, o2) => {
      const d1Numeric = isNumeric(o1.district__district_id);
      const d2Numeric = isNumeric(o2.district__district_id);
      // Sorting different types of district IDs is easiest because the numeric IDs always come first.
      if (d1Numeric !== d2Numeric) {
        // They're not the same type, so if district 1 is numeric, then it's first, otherwise it's
        // district 2 that is numeric, so it comes first.
        return d1Numeric ? -1 : 1;
      }
      // They're both the same type so we need to compare the values directly
      // locale_compare has a nifty numeric mode that we can use to do numeric comparisons on strings.
      return o1.district__district_id.localeCompare(o2.district__district_id, "en", {
        numeric: d1Numeric,
      });
    };

    if (officialSortOrder === OfficialSort.district) {
      // Avoid altering the officialsData array.
      return officialsData.slice().sort(sortByDistrict);
    } else if (officialSortOrder === OfficialSort.role) {
      return officialsData.slice().sort(function (o1, o2) {
        return o1.role.localeCompare(o2.role, "en");
      });
    } else {
      return officialsData.slice().sort(function (o1, o2) {
        return o1.last_name.localeCompare(o2.last_name, "en");
      });
    }
  }, [officialSortOrder, officialsData]);

  const preventDefault = (event) => event.preventDefault();

  const handleExpandColumn = (col) => {
    if (col === expandedColumn) {
      setExpandedColumn("");
    } else {
      setExpandedColumn(col);
    }
  };

  const handleLeftPane = (e, sel) => {
    setLeftPane(sel);
  };

  const handleRightPane = (e, sel) => {
    setRightPane(sel);
  };

  const refs = officialsState.reduce((acc, official, idx) => {
    acc[idx] = React.createRef();
    return acc;
  }, {});

  const getFacebookInAppSearchUrl = () => {
    const url = `https://www.facebook.com/search/top?q=${officialsState[count]["first_name"]} ${officialsState[count]["last_name"]} ${officialsState[count]["chamber__name_formal"]}`;
    window.open(url,'_blank');
  }

  const openAllSocialMediaUrls = (officials, count) => {
    const official = officials[count]
    socialTypes.reverse().forEach((x) => {
      if (typeof official[x] === "string" && official[x].length > 0) {
        const ids = official[x].includes(";")
          ? official[x].split(";")
          : [official[x]]
        ids.forEach(y=>{
          y = y.trim()
          switch(x) {
            case 'TWITTER':
              window.open( 'https://www.twitter.com/'.concat(y),'_blank');
              break;
            case 'INSTAGRAM':
              window.open( 'https://www.instagram.com/'.concat(y),'_blank');
              break;
            case 'INSTAGRAM-CAMPAIGN':
              window.open( 'https://www.instagram.com/'.concat(y),'_blank');
              break;
            default:
              window.open(y,'_blank');
          }
        })
      }
    });
  }

  const handleSocialSearch = (e, platform) => {
    if (platform === "clear") {
      setLeftPane("official");
      return;
    }

    const base_url = "https://www.google.com/search?q=";
    const offn = officialsState[count]["first_name"].concat(
      "+",
      officialsState[count]["last_name"]
    );
    const chmb = officialsState[count]["chamber__name_formal"];

    var search_url =
      platform === "instagram"
        ? `${base_url}${platform}+${offn}+${chmb}`
        : `${base_url}site%3A${platform}.com+${offn}+${chmb}`;
    setSocialSearchUrl(search_url);
    setLeftPane("socialsearch");
  };

  const handleSocialCheck = (link) => {
    setSocialCheckUrl(link);
    setLeftPane("socialcheck");
  };

  const isString = (obj) => {
    if (typeof obj === "string" && obj.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSeatToggle = (_, sortBy) => {
    setOfficialSortOrder(sortBy);
  };

  const getOfficialDataLocalProxy = (url) => {
    fetch(url, {
        credentials: "include",
      })
        .then((response) => response.text())
        .then((data) => JSON.parse(data))
        .then((data) => data.sort((a, b) => a.last_name.localeCompare(b.last_name)))
        .then((data) => {
          setOfficialsData(data);
        });
  }

  const getOfficialData = (url) => {
    fetch(url, {
        credentials: "include",
      })
        .then((response) => response.text())
        .then((data) => csvtojson().fromString(data))
        .then((data) => data.sort((a, b) => a.last_name.localeCompare(b.last_name)))
        .then((data) => {
          setOfficialsData(data);
        });
  }


  const getChamberOfficials = (chamber) => {
    setCount(0);
    if (!chamber) {return;}
    if (!/([0-9]{4})/.test(chamber.toString())) {return;}
    if (REACT_APP_LOCAL_FLASK_PROXY) {
      getOfficialDataLocalProxy(`/chamber/${chamber}`)
      return;
    }
    getOfficialData(`${SERVER_ROOT}/v3.1/admin/chamber_officials/${chamber}.csv`)
  };

  const getStateExecs = (state_abbr) => {
    setCount(0);
    if (!state_abbr) {return;}
    if (!/([a-zA-Z]{2})/.test(state_abbr)) {return;}
    if (REACT_APP_LOCAL_FLASK_PROXY) {
      getOfficialDataLocalProxy(`/execs/${state_abbr}`)
      return;
    }
    getOfficialData(`${SERVER_ROOT}/v3.1/admin/exec_officials_by_state/${state_abbr}.csv`)
  };

  const handleClickOpenUrlDialog = () => {
    setOpenUrlDialog(true);
  };

  const handleSetUrlAppendString = () => {
    setOpenUrlDialog(false);
  };

  const handleCancelUrlAppendString = () => {
    setOpenUrlDialog(false);
    setUrlAppendString("");
  };

  const handleChangeUrlAppendString = (event) => {
    setUrlAppendString(event.target.value);
  };

  const handleDeleteUrlAppendChip = () => {
    setUrlAppendString("");
  };

  const renderSelectedFrameTable = (official) => {
    return (
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow
            key="addr1"
            className={classes.tableRow}
            selected={true}
            classes={{ selected: classes.selected }}
          >
            <TableCell>
              <Avatar className={classes.small}>P</Avatar>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" display="block">
                A1: {official.primary_address_1}
              </Typography>
              <Typography variant="body2" display="block">
                A2: {official.primary_address_2}
              </Typography>
              <Typography variant="body2" display="block">
                A3: {official.primary_address_3}
              </Typography>
              <Typography variant="body2" display="block">
                {official.primary_city.concat(" ")}
                {official.primary_state.concat(" ")}
                {official.primary_postal_code}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" display="block">
                P1: {official.primary_phone_1}
              </Typography>
              <Typography variant="body2" display="block">
                F1: {official.primary_fax_1}
              </Typography>
              <Typography variant="body2" display="block">
                P2: {official.primary_phone_2}
              </Typography>
              <Typography variant="body2" display="block">
                F2: {official.primary_fax_2}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Button size="small" variant="outlined" onClick={() => setRightPane("admin")}>
                {" "}
                Admin
              </Button>

              <Typography variant="body2" display="block">
                S: {official.valid_from.split(" ")[0]}
              </Typography>
              <Typography variant="body2" display="block">
                E: {official.valid_to.split(" ")[0]}
              </Typography>
              <Typography variant="body2" display="block">
                BIO: {isString(official.description_1) && official.description_1.length}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow
            key="addr2"
            className={classes.tableRow}
            selected={true}
            classes={{ selected: classes.selected }}
          >
            <TableCell>
              <Avatar className={classes.small}>S</Avatar>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" display="block">
                A1: {official.secondary_address_1}
              </Typography>
              <Typography variant="body2" display="block">
                A2: {official.secondary_address_2}
              </Typography>
              <Typography variant="body2" display="block">
                A3: {official.secondary_address_3}
              </Typography>
              <Typography variant="body2" display="block">
                {official.secondary_city.concat(" ")}
                {official.secondary_state.concat(" ")}
                {official.secondary_postal_code}
              </Typography>
            </TableCell>

            <TableCell align="left">
              <Typography variant="body2" display="block">
                P1: {official.secondary_phone_1}
              </Typography>
              <Typography variant="body2" display="block">
                F1: {official.secondary_fax_1}
              </Typography>
              <Typography variant="body2" display="block">
                P2: {official.secondary_phone_2}
              </Typography>
              <Typography variant="body2" display="block">
                F2: {official.secondary_fax_2}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" display="block">
                URL1: {isString(official.url_1) && official.url_1.substring(0, 50)}
              </Typography>
              <Typography variant="body2" display="block">
                URL2: {isString(official.url_2) && official.url_2.substring(0, 50)}
              </Typography>
              <Typography variant="body2" display="block">
                WF: {isString(official.web_form_url) && official.web_form_url.substring(0, 50)}
              </Typography>
              <Typography variant="body2" display="block">
                EM2: {isString(official.email_2) && official.email_2.substring(0, 50)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const renderSelectedFrameSubtable = (official) => {
    return (
      <Paper className={classes.socialPaper}>
        {renderSocialChips(official)}
        {isString(official.titles) && renderTitles(official)}
      </Paper>
    );
  };

  const renderPartyAvatar = (p) => {
    const l = p.substring(0, 1);
    if (p.substring(0, 1) === "D") {
      return <Avatar className={`${classes.blue} ${classes.small}`}>{l}</Avatar>;
    } else if (p.substring(0, 1) === "R") {
      return <Avatar className={`${classes.red} ${classes.small}`}>{l}</Avatar>;
    }
  };

  const renderFrameTableRows = (official, i) => {
    return (
      <div
        key={i}
        ref={refs[i]}
        onClick={() => {
          setCount(i);
        }}
      >
        {renderFrameTableTopRow(official)}
        {count === i && renderSelectedFrameTable(official)}
        {count === i && renderSelectedFrameSubtable(official)}
      </div>
    );
  };

  const renderFrameTableTopRow = (official) => {
    const ciceroAdminUrl = `${SERVER_ROOT}/v3.1/admin/webservice/official/${official.id}/change/`;
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs>
            {official.role}
          </Grid>
          <Grid item xs>
            <Link href={official.photo_origin_url} onClick={preventDefault}>
              <Avatar
                alt={official.last_name}
                src={official.photo_origin_url}
                className={classes.small}
              />
            </Link>
          </Grid>
          <Grid item xs={2}>
            {official.first_name} {official.last_name}
          </Grid>
          <Grid item xs={1}>
            {official.district__district_id}
          </Grid>
          <Grid item xs>
            {partyToggle ? renderPartyAvatar(official.party) : official.party.substring(0, 3)}
          </Grid>
          <Grid item xs={1}>
            <Link href={ciceroAdminUrl} onClick={preventDefault} variant="body2">
              {official.id}
            </Link>
          </Grid>
          <Grid item xs={1}>
            {official.url_1 !== "" && (
              <Link href={official.url_1} onClick={preventDefault} variant="body2">
                URL 1
              </Link>
            )}
          </Grid>
          <Grid item xs={1}>
            {official.url_2 !== "" && (
              <Link href={official.url_2} onClick={preventDefault} variant="body2">
                URL 2
              </Link>
            )}
          </Grid>
          <Grid item xs={1}>
            {official.web_form_url !== "" && (
              <Link href={official.web_form_url} onClick={preventDefault} variant="body2">
                WF
              </Link>
            )}
          </Grid>
          <Grid item xs={2}>
            {official.email_1}
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderFrameMiniTable = () => {
    const official = officialsState[count];
    return (
      <div>
        {renderFrameTableTopRow(official)}
        {renderSelectedFrameTable(official)}
        {renderSelectedFrameSubtable(official)}
      </div>
    );
  };

  const renderFrameTable = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs>
            <Box fontWeight="fontWeightBold">Role</Box>
          </Grid>
          <Grid item xs>
            <Box fontWeight="fontWeightBold">Photo</Box>
          </Grid>
          <Grid item xs={2}>
            <Box fontWeight="fontWeightBold">Name</Box>
          </Grid>
          <Grid item xs={1}>
            <Box fontWeight="fontWeightBold">Seat</Box>
          </Grid>
          <Grid item xs>
            <Box fontWeight="fontWeightBold" onClick={() => setPartyToggle(!partyToggle)}>
              Party
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box fontWeight="fontWeightBold">Cicero Admin</Box>
          </Grid>
          <Grid item xs={1}>
            <Box fontWeight="fontWeightBold">Url 1</Box>
          </Grid>
          <Grid item xs={1}>
            <Box fontWeight="fontWeightBold">Url 2</Box>
          </Grid>
          <Grid item xs={1}>
            <Box fontWeight="fontWeightBold">Webform</Box>
          </Grid>
          <Grid item xs={2}>
            <Box fontWeight="fontWeightBold">Email_1</Box>
          </Grid>
        </Grid>
        {officialsState.map(renderFrameTableRows, refs)}
      </div>
    );
  };

  const getSocialComponent = (type) => {
    const socialComponents = {
      TWITTER: TwitterIcon,
      FACEBOOK: FacebookIcon,
      "FACEBOOK-OFFICIAL": FacebookIcon,
      "FACEBOOK-CAMPAIGN": FacebookIcon,
      LINKEDIN: LinkedInIcon,
      INSTAGRAM: InstagramIcon,
      "INSTAGRAM-CAMPAIGN": InstagramIcon,
    };
    return socialComponents[type];
  };

  const getSocialColor = (type) => {
    const colorTemplate = {
      TWITTER: "#1DA1F2",
      FACEBOOK: "#4267B2",
      "FACEBOOK-OFFICIAL": "#4267B2",
      "FACEBOOK-CAMPAIGN": "#4267B2",
      LINKEDIN: "#2867b2",
      INSTAGRAM: "#e4405f",
      "INSTAGRAM-CAMPAIGN": "#e4405f",
    };
    return colorTemplate[type];
  };

  const getUrlTemplate = (type, val) => {
    const urlTemplate = {
      TWITTER: `https://twitter.com/${val}`,
      FACEBOOK: val,
      "FACEBOOK-OFFICIAL": val,
      "FACEBOOK-CAMPAIGN": val,
      LINKEDIN: val,
      INSTAGRAM: `https://www.instagram.com/${val}`,
      "INSTAGRAM-CAMPAIGN": `https://www.instagram.com/${val}`,
    };
    return urlTemplate[type];
  };

  const renderSocialIcon = (x, i) => {
    const SpecificSocialIcon = getSocialComponent(x.type);
    const urlSocial = getUrlTemplate(x.type, x.val);
    return (
      <Tooltip title={x.val} aria-label="add" key={i}>
        <SpecificSocialIcon onClick={() => handleSocialCheck(urlSocial)} />
      </Tooltip>
    );
  };

  const renderSocialChip = (x, i) => {
    const SpecificSocialIcon = getSocialComponent(x.type);
    const urlSocial = getUrlTemplate(x.type, x.val);
    const color = getSocialColor(x.type);
    return (
      <React.Fragment key={i}>
        <Tooltip title="Show in Left Pane" aria-label="leftpane">
          {x.type === "FACEBOOK-OFFICIAL" ? (
            <Badge
              color="secondary"
              badgeContent="O"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Chip
                color="primary"
                style={{ backgroundColor: color }}
                size="small"
                avatar={
                  <Avatar>
                    <SpecificSocialIcon />
                  </Avatar>
                }
                label={x.val}
                onClick={() => handleSocialCheck(urlSocial)}
              />
            </Badge>
          ) : x.type === "FACEBOOK-CAMPAIGN" || x.type === "INSTAGRAM-CAMPAIGN" ? (
            <Badge
              color="secondary"
              badgeContent="C"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Chip
                color="primary"
                style={{ backgroundColor: color }}
                size="small"
                avatar={
                  <Avatar>
                    <SpecificSocialIcon />
                  </Avatar>
                }
                label={x.val}
                onClick={() => handleSocialCheck(urlSocial)}
              />
            </Badge>
          ) : (
            <Chip
              color="primary"
              style={{ backgroundColor: color }}
              size="small"
              avatar={
                <Avatar>
                  <SpecificSocialIcon />
                </Avatar>
              }
              label={x.val}
              onClick={() => handleSocialCheck(urlSocial)}
            />
          )}
        </Tooltip>
        <OpenInNewIcon fontSize="small" onClick={() => window.open(urlSocial, "_blank")} />
      </React.Fragment>
    );
  };

  const renderTitleChip = (x, i) => {
    return (
      <Chip
        key={`titlechip${i}`}
        size="small"
        color="secondary"
        avatar={
          <Avatar>
            <TitleIcon />
          </Avatar>
        }
        label={x}
      />
    );
  };

  const renderSocialIcons = (official) => {
    var social_arr = [];
    socialTypes.forEach((x) => {
      if (typeof official[x] === "string" && official[x].length > 0) {
        const ids = official[x].split(";");
        const id_objs = ids.map((y) => ({ type: x, val: y }));
        social_arr.push(...id_objs);
      }
    });
    return social_arr.map(renderSocialIcon);
  };

  const renderSocialChips = (official) => {
    var social_arr = [];
    socialTypes.forEach((x) => {
      if (typeof official[x] === "string" && official[x].length > 0) {
        const ids = official[x].split(";");
        const id_objs = ids.map((y) => ({ type: x, val: y }));
        social_arr.push(...id_objs);
      }
    });
    return social_arr.map(renderSocialChip);
  };

  const renderTitles = (official) => {
    var titles_arr = official.titles.split(";");
    return titles_arr.map(renderTitleChip);
  };

  const renderTableRows = (official, i) => {
    return (
      <TableRow
        key={official.id}
        selected={i === count}
        classes={{ selected: classes.selected }}
        className={classes.tableRow}
        onClick={() => {
          setCount(i);
        }}
      >
        <TableCell component="th" scope="row">
          {official.role}
        </TableCell>
        <TableCell align="left">
          <Link href={official.photo_origin_url} onClick={preventDefault} color="inherit">
            {expandedColumn === "photo" ? (
              official.photo_origin_url
            ) : (
              <Avatar
                alt={official.last_name}
                src={official.photo_origin_url}
                className={classes.small}
              />
            )}
          </Link>
        </TableCell>
        <TableCell align="left">
          {expandedColumn === "name"
            ? official.first_name.concat(" ", official.last_name)
            : [
                official.salutation,
                official.first_name,
                official.middle_initial,
                official.last_name,
                official.name_suffix,
              ]
                .filter(Boolean)
                .join(" ")}
        </TableCell>
        <TableCell align="right">{official.district__district_id}</TableCell>
        <TableCell align="right">
          {partyToggle ? renderPartyAvatar(official.party) : official.party.substring(0, 3)}
        </TableCell>
        <TableCell align="right">
          {expandedColumn === "title"
            ? official.titles
            : isString(official.titles)
            ? official.titles.substring(0, 8).concat("...")
            : ""}
        </TableCell>
        {expandedColumn === "term" ? (
          <React.Fragment>
            <TableCell align="right">{official.valid_from.split(" ")[0]}</TableCell>
            <TableCell align="right">{official.valid_to.split(" ")[0]}</TableCell>
          </React.Fragment>
        ) : (
          <TableCell align="right">
            {official.valid_from
              .split("-")[0]
              .substring(2)
              .concat(" - ", official.valid_to.split("-")[0].substring(2))}
          </TableCell>
        )}

        <TableCell align="right">{official.primary_address_1}</TableCell>
        <TableCell align="right">{official.primary_address_2}</TableCell>
        <TableCell align="right">{official.primary_address_3}</TableCell>
        {expandedColumn !== "pri_phone_fax" ? (
          <React.Fragment>
            <TableCell align="right">{official.primary_phone_1}</TableCell>
            <TableCell align="right">{official.primary_fax_1}</TableCell>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableCell align="right">{official.primary_phone_1}</TableCell>
            <TableCell align="right">{official.primary_fax_1}</TableCell>
            <TableCell align="right">{official.primary_phone_2}</TableCell>
            <TableCell align="right">{official.primary_fax_2}</TableCell>
          </React.Fragment>
        )}
        {expandedColumn !== "sec_phone_fax" ? (
          <React.Fragment>
            <TableCell align="right">{official.secondary_phone_1}</TableCell>
            <TableCell align="right">{official.secondary_fax_1}</TableCell>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableCell align="right">{official.secondary_phone_1}</TableCell>
            <TableCell align="right">{official.secondary_fax_1}</TableCell>
            <TableCell align="right">{official.secondary_phone_2}</TableCell>
            <TableCell align="right">{official.secondary_fax_2}</TableCell>
          </React.Fragment>
        )}
        {!socialTableToggle && <TableCell align="right">{renderSocialIcons(official)}</TableCell>}
        {socialTableToggle && (
          <TableCell align="right">
            {typeof official["FACEBOOK-OFFICIAL"] === "string" &&
              official["FACEBOOK-OFFICIAL"].length > 0 && (
                <Tooltip title={official["FACEBOOK-OFFICIAL"]} aria-label="add">
                  <FacebookIcon onClick={() => handleSocialCheck(official["FACEBOOK-OFFICIAL"])} />
                </Tooltip>
              )}
          </TableCell>
        )}
        {socialTableToggle && (
          <TableCell align="right">
            {typeof official["FACEBOOK-CAMPAIGN"] === "string" &&
              official["FACEBOOK-CAMPAIGN"].length > 0 && (
                <Tooltip title={official["FACEBOOK-CAMPAIGN"]} aria-label="add">
                  <FacebookIcon onClick={() => handleSocialCheck(official["FACEBOOK-CAMPAIGN"])} />
                </Tooltip>
              )}
          </TableCell>
        )}
        {socialTableToggle && (
          <TableCell align="right">
            {typeof official["FACEBOOK"] === "string" && official["FACEBOOK"].length > 0 && (
              <Tooltip title={official["FACEBOOK"]} aria-label="add">
                <FacebookIcon onClick={() => handleSocialCheck(official["FACEBOOK"])} />
              </Tooltip>
            )}
          </TableCell>
        )}
        {socialTableToggle && (
          <TableCell align="right">
            {typeof official["TWITTER"] === "string" && official["TWITTER"].length > 0 && (
              <Tooltip title={official["TWITTER"]} aria-label="add">
                <TwitterIcon
                  onClick={() => handleSocialCheck(`https://twitter.com/${official["TWITTER"]}`)}
                />
              </Tooltip>
            )}
          </TableCell>
        )}
        {socialTableToggle && (
          <TableCell align="right">
            {typeof official["INSTAGRAM"] === "string" && official["INSTAGRAM"].length > 0 && (
              <Tooltip title={official["INSTAGRAM"]} aria-label="add">
                <InstagramIcon
                  onClick={() =>
                    handleSocialCheck(`https://instagram.com/${official["INSTAGRAM"]}`)
                  }
                />
              </Tooltip>
            )}
          </TableCell>
        )}
        {socialTableToggle && (
          <TableCell align="right">
            {typeof official["LINKEDIN"] === "string" && official["LINKEDIN"].length > 0 && (
              <Tooltip title={official["LINKEDIN"]} aria-label="add">
                <LinkedInIcon onClick={() => handleSocialCheck(official["LINKEDIN"])} />
              </Tooltip>
            )}
          </TableCell>
        )}

        <TableCell align="left">
          {official.url_1 !== "" && (
            <Link href={official.url_1} onClick={preventDefault} color="inherit">
              {expandedColumn === "url_1" ? official.url_1 : "URL 1"}
            </Link>
          )}
        </TableCell>
        <TableCell align="right">
          {official.url_2 !== "" && (
            <Link href={official.url_2} onClick={preventDefault} color="inherit">
              {expandedColumn === "url_2" ? official.url_2 : "URL 2"}
            </Link>
          )}
        </TableCell>
        <TableCell align="right">
          {official.web_form_url !== "" && (
            <Link href={official.web_form_url} onClick={preventDefault} color="inherit">
              {expandedColumn === "webform" ? official.web_form_url : "WF"}
            </Link>
          )}
        </TableCell>
        <TableCell align="right">
          {isString(official.description_1) && official.description_1.length}
        </TableCell>
        {expandedColumn !== "email" ? (
          <TableCell align="right">{official.email_1}</TableCell>
        ) : (
          <React.Fragment>
            <TableCell align="right">{official.email_2}</TableCell>
            <TableCell align="right">{official.email_1}</TableCell>
          </React.Fragment>
        )}
      </TableRow>
    );
  };

  const renderRightPane = (rightUrl) => {
    return (
      <iframe
        id="ifrRight"
        title="Right"
        width="100%"
        height={showMainSummary ? "800px" : "1100px"}
        src={rightUrl}
      ></iframe>
    );
  };

  const renderTable = () => {
    return (
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell align="left" onClick={() => handleExpandColumn("photo")}>
              Photo
            </TableCell>
            <TableCell align="left" onClick={() => handleExpandColumn("name")}>
              Name
            </TableCell>
            <TableCell align="right">Seat</TableCell>
            <TableCell align="right" onClick={() => setPartyToggle(!partyToggle)}>
              Party
            </TableCell>
            <TableCell align="right" onClick={() => handleExpandColumn("title")}>
              Title
            </TableCell>
            {expandedColumn === "term" ? (
              <React.Fragment>
                <TableCell align="right" onClick={() => handleExpandColumn("")}>
                  Start
                </TableCell>
                <TableCell align="right" onClick={() => handleExpandColumn("")}>
                  End
                </TableCell>
              </React.Fragment>
            ) : (
              <TableCell align="right" onClick={() => handleExpandColumn("term")}>
                Term
              </TableCell>
            )}
            <TableCell align="right">Addr 1</TableCell>
            <TableCell align="right">Addr 2</TableCell>
            <TableCell align="right">Addr 3</TableCell>
            {expandedColumn !== "pri_phone_fax" ? (
              <React.Fragment>
                <TableCell align="right" onClick={() => handleExpandColumn("pri_phone_fax")}>
                  Phone1
                </TableCell>
                <TableCell align="right" onClick={() => handleExpandColumn("pri_phone_fax")}>
                  Fax1
                </TableCell>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableCell align="right">Phone1*</TableCell>
                <TableCell align="right">Fax1*</TableCell>
                <TableCell align="right">Phone2*</TableCell>
                <TableCell align="right">Fax2*</TableCell>
              </React.Fragment>
            )}
            {expandedColumn !== "sec_phone_fax" ? (
              <React.Fragment>
                <TableCell align="right" onClick={() => handleExpandColumn("sec_phone_fax")}>
                  SecPhone1
                </TableCell>
                <TableCell align="right" onClick={() => handleExpandColumn("sec_phone_fax")}>
                  SecFax1
                </TableCell>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableCell align="right">SecPhone1*</TableCell>
                <TableCell align="right">SecFax1*</TableCell>
                <TableCell align="right">SecPhone2*</TableCell>
                <TableCell align="right">SecFax2*</TableCell>
              </React.Fragment>
            )}
            {!socialTableToggle && (
              <TableCell align="right" onClick={() => setSocialTableToggle(!socialTableToggle)}>
                Social
              </TableCell>
            )}
            {socialTableToggle && (
              <TableCell align="right" onClick={() => setSocialTableToggle(!socialTableToggle)}>
                FB-O
              </TableCell>
            )}
            {socialTableToggle && (
              <TableCell align="right" onClick={() => setSocialTableToggle(!socialTableToggle)}>
                FB-C
              </TableCell>
            )}
            {socialTableToggle && (
              <TableCell align="right" onClick={() => setSocialTableToggle(!socialTableToggle)}>
                FB
              </TableCell>
            )}
            {socialTableToggle && (
              <TableCell align="right" onClick={() => setSocialTableToggle(!socialTableToggle)}>
                TW
              </TableCell>
            )}
            {socialTableToggle && (
              <TableCell align="right" onClick={() => setSocialTableToggle(!socialTableToggle)}>
                IG
              </TableCell>
            )}
            {socialTableToggle && (
              <TableCell align="right" onClick={() => setSocialTableToggle(!socialTableToggle)}>
                LI
              </TableCell>
            )}
            <TableCell align="left" onClick={() => handleExpandColumn("url_1")}>
              url_1
            </TableCell>
            <TableCell align="right" onClick={() => handleExpandColumn("url_2")}>
              url_2
            </TableCell>
            <TableCell align="right" onClick={() => handleExpandColumn("webform")}>
              webform
            </TableCell>
            <TableCell align="right">bio</TableCell>
            {expandedColumn !== "email" ? (
              <TableCell align="right" onClick={() => handleExpandColumn("email")}>
                Email
              </TableCell>
            ) : (
              <React.Fragment>
                <TableCell align="right">Email_2*</TableCell>
                <TableCell align="right">Email_1*</TableCell>
              </React.Fragment>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{officialsState.map(renderTableRows)}</TableBody>
      </Table>
    );
  };

  const nextOfficial = useCallback(() => {
    setCount(count + 1);
    if (!["official", "appendVal"].includes(leftPane)) {
      setLeftPane("official");
    }
  }, [count, leftPane]);

  const backOfficial = useCallback(() => {
    setCount(count - 1);
    if (!["official", "appendVal"].includes(leftPane)) {
      setLeftPane("official");
    }
  }, [count, leftPane]);

  useEffect(() => {
    setChamber(router_id);
  }, [router_id]);

  useEffect(() => {
    setExecsState(execs_state_abbr);
  }, [execs_state_abbr]);

  useEffect(() => {
    getStateExecs(execsState);
  }, [execsState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getChamberOfficials(chamber);
  }, [chamber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Scroll to selected official when 'count' changes
    if (typeof refs[count] !== "undefined") {
      if (refs[count].current !== null) {
        refs[count].current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [count, refs]);

  // Add keyboard event listeners
  useEffect(() => {
    function handleEvent(e) {
      switch (e.key) {
        case "ArrowRight":
          nextOfficial();
          break;
        case "ArrowLeft":
          backOfficial();
          break;
        default:
          // Do nothing
          break;
      }
    }
    window.addEventListener("keydown", handleEvent);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", handleEvent);
    };
  }, [backOfficial, nextOfficial]);

  const leftUrl =
    leftPane === "socialsearch"
      ? socialSearchUrl
      : leftPane === "socialcheck"
      ? socialCheckUrl
      : leftPane === "appendVal"
      ? officialsState[count]
        ? `${officialsState[count]["url_1"]}${urlAppendString}`
        : officialsState[0]["chamber__url"]
      : leftPane === "official"
      ? officialsState[count] &&
        (officialsState[count]["url_1"] === ""
          ? officialsState[0]["chamber__url"]
          : officialsState[count]["url_1"])
      : officialsState[0] && officialsState[0]["chamber__url"];

  const rightUrl = officialsState[count]
    ? `${SERVER_ROOT}/v3.1/admin/webservice/official/${officialsState[count]["id"]}/change/`
    : officialsState[0]
    ? `${SERVER_ROOT}/v3.1/admin/webservice/chamber/${officialsState[0]["chamber"]}/change/`
    : "";

  const chamber_csv_url = `${SERVER_ROOT}/admin/chamber_officials/${chamber}.csv`;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              variant="contained"
              color="primary"
              onClick={backOfficial}
              startIcon={<NavigateBeforeIcon />}
            >
              Back Official
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={nextOfficial}
              endIcon={<NavigateNextIcon />}
            >
              Next Official
            </Button>
          </ButtonGroup>
          <Typography gutterBottom>
            Now viewing official {count + 1} of {officialsState.length} in this chamber{" "}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <div className="dd-header">
            <IconButton
                onClick={getFacebookInAppSearchUrl}
                className={classes.bannerButton}
              >
              <Tooltip title="Facebook In App Search" aria-label="facebook-in-app-search">
                <FacebookIcon />
              </Tooltip>
            </IconButton>
            <ToggleButtonGroup
              value={socialSearchUrl}
              exclusive
              onChange={handleSocialSearch}
              aria-label="text alignment"
            >
              <Tooltip title="Facebook Search" aria-label="facebook-search">
                <ToggleButton value="facebook" aria-label="facebook">
                  <FacebookIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Twitter Search" aria-label="twitter-search">
                <ToggleButton value="twitter" aria-label="twitter">
                  <TwitterIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="LinkedIn Search" aria-label="linkedin-search">
                <ToggleButton value="linkedin" aria-label="linkedin">
                  <LinkedInIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Instagram Search" aria-label="instagram-search">
                <ToggleButton value="instagram" aria-label="instagram">
                  <InstagramIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Clear Social Search" aria-label="clear-search">
                <ToggleButton value="clear" aria-label="clear">
                  <ClearIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
            <IconButton
                onClick={() => openAllSocialMediaUrls(officialsState, count)}
                className={classes.bannerButton}
              >
              <Tooltip title="Open Tabs for all Social Urls" aria-label="facebook-in-app-search">
                <ClearAllIcon />
              </Tooltip>
            </IconButton>
          </div>
          <Typography gutterBottom>Social Media Discovery</Typography>
        </Grid>
        <Grid item xs={1}>
          <div className="dd-header">
            {urlAppendString !== "" && !openUrlDialog ? (
              <Chip
                icon={<QueuePlayNextIcon />}
                label={urlAppendString}
                onDelete={handleDeleteUrlAppendChip}
                color="secondary"
                variant="outlined"
              />
            ) : (
              <IconButton
                onClick={handleClickOpenUrlDialog}
                className={classes.bannerButton}
              >
                <Tooltip title="Set Url Append String" aria-label="set-append-url">
                  <QueuePlayNextIcon />
                </Tooltip>
              </IconButton>
            )}
            <Dialog
              open={openUrlDialog}
              onClose={handleCancelUrlAppendString}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Set Value to Append to Official Url</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter a string here (eg. "contact/") to append it to Official Urls
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="URL append string"
                  onChange={(e) => {
                    handleChangeUrlAppendString(e);
                  }}
                  type="text"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelUrlAppendString} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSetUrlAppendString} color="primary">
                  Set Url Appender
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Typography gutterBottom>URL appender</Typography>
        </Grid>
        <Grid item xs={1}>
          <div className="dd-header">
            <ToggleButtonGroup
              value={leftPane}
              exclusive
              onChange={handleLeftPane}
              aria-label="text alignment"
            >
              <ToggleButton value="chamber" aria-label="chamber">
                <Tooltip title="Chamber URL" aria-label="chamber">
                  <GroupIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="official" aria-label="official">
                <Tooltip title="Official URL" aria-label="official">
                  <PersonIcon />
                </Tooltip>
              </ToggleButton>
              {urlAppendString !== "" && (
                <ToggleButton value="appendVal" aria-label="appendVal">
                  <Tooltip title="Url Append String" aria-label="appendVal">
                    <QueuePlayNextIcon />
                  </Tooltip>
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </div>
          <Typography gutterBottom>Website Selection</Typography>
        </Grid>
        <Grid item xs={2}>
          <div className="dd-header">
            <ToggleButtonGroup
              value={rightPane}
              exclusive
              onChange={handleRightPane}
              aria-label="text alignment"
            >
              <ToggleButton value="table" aria-label="table">
                <Tooltip title="List Summary" aria-label="summary">
                  <ListIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="admin" aria-label="admin">
                <Tooltip title="Cicero Admin" aria-label="admin">
                  <PersonIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Typography gutterBottom>Cicero Selection</Typography>
        </Grid>
        <Grid item xs={1}>
          <div className="dd-header">
            <ToggleButtonGroup
              value={officialSortOrder}
              exclusive
              onChange={handleSeatToggle}
              aria-label="text alignment"
            >
              <ToggleButton value={OfficialSort.alpha} aria-label="table">
                <Tooltip title="Sort Officials by Name" aria-label="sort-name">
                  <SortByAlphaIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={OfficialSort.district} aria-label="admin">
                <Tooltip title="Sort Officials by District" aria-label="admin">
                  <MapIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={OfficialSort.role} aria-label="admin">
                <Tooltip title="Sort Officials by Role" aria-label="admin">
                  <BubbleChartIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Typography gutterBottom>Official Display Order</Typography>
        </Grid>
        <Grid item xs={1}>
          <Box display="flex" p={2} justifyContent="center">
            <Tooltip title="Toggle Bottom Table" aria-label="bottom-panel-toggle">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="Normal"
                      checked={showMainSummary}
                      onChange={() => {
                        setShowMainSummary(!showMainSummary);
                      }}
                    />
                  }
                  label="Summary Table"
                />
              </FormGroup>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" p={2} justifyContent="center">
            <Tooltip title="Open Chamber CSV in New Tab" aria-label="chamber csv">
              <Button variant="outlined" color="secondary" target="_blank" href={chamber_csv_url}>
                Export CSV
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Link href={leftUrl} onClick={preventDefault} variant="body2">
              {leftUrl}
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Link href={rightUrl} onClick={preventDefault} variant="body2">
              {rightUrl}
            </Link>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box style={{ maxHeight: showMainSummary ? 800 : 1100 }}>
            <iframe
              id="ifrLeft"
              title="Left"
              width="100%"
              height={showMainSummary ? "800px" : "1100px"}
              src={leftUrl}
            ></iframe>
          </Box>
        </Grid>

        <Grid item xs={6}>
          {rightPane === "admin" ? (
            <Box
              style={{
                maxHeight: showMainSummary ? 800 : 1100,
                overflow: "auto",
              }}
            >
              {showMainSummary ? (
                renderRightPane(rightUrl)
              ) : (
                <React.Fragment>
                  <div>{renderFrameMiniTable()}</div>
                  <div>{renderRightPane(rightUrl)}</div>
                </React.Fragment>
              )}
            </Box>
          ) : (
            <Box
              style={{
                maxHeight: showMainSummary ? 800 : 1100,
                overflow: "auto",
              }}
            >
              {renderFrameTable()}
            </Box>
          )}
        </Grid>
      </Grid>

      {showMainSummary && (
        <Grid container spacing={3}>
          <Grid item xs={12}
                style={{
                  maxHeight: 1100,
                  overflow: "auto",
                }}
            >
            <Paper>{renderTable()}</Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
